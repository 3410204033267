<template>
    <div class="overflow-hidden">
        <section
            id="pricing"
            class="package-section background-shape-right ptb-100"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5">
                            <h2>
                                Contrate o iLub 
                            </h2>
                            <p class="lead">
                                Para continuar com uma experiência completa escolha um plano que melhor irá te atender.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    
                    <div v-for="(item, i) in array" :key="i" class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack" :class="i == 1 ? 'popular-price' : ''">
                            <div class="pt-4"><h5>{{ item.nome }}</h5></div>
                            <div class="card-header py-4 border-0 pricing-header">

                                <div class="h1 text-center mb-0">
                                    R$<span class="price font-weight-bolder"
                                        >{{ calculaPrecoPorMes(item.validade, item.valor) }}</span
                                    >
                                </div>
                                <p>por cnpj/mês</p>
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li>{{ item.descricao }}</li>
                                </ul>
                                <a
                                    href="#"
                                    class="btn solid-btn mb-3"
                                    target="_blank"
                                    >Comprar</a
                                >
                            </div>
                        </div>
                    </div>
                    
        
                </div>
                <div class="mt-5 text-center">
                    <p class="mb-2">
                        Você precisa de um serviço customizado?
                        <a href="#" class="color-primary"> Entre em contato conosco!</a>
                    </p>
                </div>
                <!--pricing faq start-->
                <div class="row mt-5">
                    <div class="col-lg-6">
                        <accordian id="accordion-1" :contents="contents" />
                    </div>
                    <div class="col-lg-6">
                        <accordian id="accordion-2" :contents="contents1" />
                    </div>
                </div>
                <!--pricing faq end-->
            </div>
        </section>
    </div>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "Pricing",
    isGray: {
        type: Boolean,
        default: false,
    },
    isPrimary: {
        type: Boolean,
        default: false,
    },
    components: {
        Accordian,
    },
    data: function () {
        return {
            array: [],
            calculaPrecoPorMes(dias, valor) {

                const meses = dias / 30

                const resultado = Number(valor) / meses

                return String(resultado.toFixed(2)).replace(".", ",")

            },
            contents: [
                {
                    title: "Como faço para criar uma conta no iLub?",
                    description:
                        "Para criar sua conta, basta clicar no botão \"Inscreva-se\" no site ou baixar o app. Preencha o formulário com seu nome, documento, telefone, e-mail e crie uma senha. Acesso liberado imediatamente! ",
                    active: false,
                    icon: "ti-help-alt",
                },

                {
                    title: "Como consultar a ficha técnica de um veículo no app?",
                    description:
                        "No app, você pode consultar o manual técnico do veículo de três formas: digitando a placa, ou o modelo do veículo, ou selecionando a montadora, ano e modelo. Todas as opções mostram as indicações corretas de óleo e filtros.",
                    active: false,
                    icon: "ti-help-alt",
                },
                {
                    title: "Como alterar as marcas na recomendação de produtos?",
                    description:
                        "Abra o menu no canto superior esquerdo, vá até \"Configurações\" e selecione os produtos com as marcas configuradas. Escolha a marca que deseja que apareça primeiro nas recomendações.",
                    active: false,
                    icon: "ti-help-alt",
                },
            ],
            contents1: [
                {
                    title: "Como gerar a etiqueta de troca ao final da revisão?",
                    description:
                        "Após registrar a troca na tela de produtos indicados, clique em \"Registrar Troca\". Preencha os dados do atendimento e, ao final, clique em \"Gerar Etiqueta\" para visualizar e imprimir a etiqueta de troca de óleo",
                    active: false,
                    icon: "ti-help-alt",
                },
                {
                    title: "Posso compartilhar assinatura?",
                    description:
                        "Não, a assinatura do iLub é exclusiva para 1 usuário. Caso precise de algum atendimento especial, entre em contato conosco.",
                    active: false,
                    icon: "ti-help-alt",
                },
                {
                    title: "Posso cancelar a assinatura do app?",
                    description:
                        "Se a renovação não ocorrer, a assinatura será cancelada automaticamente, de forma simples e sem burocracia.",
                    active: false,
                    icon: "ti-help-alt",
                },
            ],
        };
    },
    methods: {

        async buscarPrecos() {

            let response = await fetch(`${window.url}/plano`)

            const { data } = await response.json()

            this.array = data

        },
    },
    mounted() {
        this.buscarPrecos();
    }
   
};
</script>

<style>
</style>