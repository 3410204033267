<template>
    <section
        id="team"
        class="team-member-section ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Funcionalidades que facilitam seu dia a dia</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div
                    v-for="member in members"
                    v-bind:key="member.imageUrl"
                    class="col-sm-6 col-lg-3"
                >
                    <team-member
                        :name="member.name"
                        :post="member.post"
                        :desc="member.desc"
                        :image-url="member.imageUrl"
                        :is-rounded="isRounded"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TeamMember from "../../components/TeamMember";
export default {
    name: "Team",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        isRounded: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        TeamMember,
    },
    data() {
        return {
            members: [
                {
                    name: "Busca rápida \nde produtos",
                    post: "Localize óleos e filtros \ncom base no veículo.",
                    desc:
                        "Authoritatively engage leading-edge processes tactical capital",
                    imageUrl: "img/busca-rapida.png",
                },
                {
                    name: "CRM para \nfidelização",
                    post: "Acompanhe clientes e avise \nsobre a próxima troca.",
                    desc:
                        "Authoritatively engage leading-edge processes tactical capital",
                    imageUrl: "img/crm-fidel.png",
                },
                {
                    name: "Emissão \nde etiquetas",
                    post: "Crie etiquetas de troca de \nóleo com dados completos em \npoucos cliques.",
                    desc:
                        "Authoritatively engage leading-edge processes tactical capital",
                    imageUrl: "img/gerenciamento-estoque.png",
                },
                {
                    name: "Histórico de \nserviços",
                    post: "Veja quando foi a última \ntroca e o próximo período \nsugerido.",
                    desc:
                        "Authoritatively engage leading-edge processes tactical capital",
                    imageUrl: "img/historico-servico.png",
                },
            ],
        };
    },
};
</script>

<style>
</style>