var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"client-section pt-100",class:_vm.isGray ? 'gray-light-bg' : ''},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag",attrs:{"items":1,"nav":false,"autoplay":true,"dots":true,"loop":true,"margin":15,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":6000,"responsive":{
                        0: { items: 2 },
                        500: { items: 3 },
                        600: { items: 4 },
                        800: { items: 5 },
                        1200: { items: 6 },
                    }}},[_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Fiat.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Chevrolet.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Vw.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Ford.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Hyundai.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Toyota.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Honda.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Peugeot.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Renault.png","alt":"client logo"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }