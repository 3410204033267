<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="row p-4">
                        <div class="col-md-10">
                            <p class="mb-0" style="font-size: 16px;font-family: 'Open Sans';font-weight:600;">Cadastro</p>
                            <p class="mb-0" style="font-size: 16px;font-family: 'Open Sans';font-weight:400;">Preencha os campos abaixo:</p>
                        </div>
                        <div class="col-md-2">
                            <button class="close-button" @click="close">x</button>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-12 pb-3 message-box d-done"
                            v-bind:class="{
                                'd-none': !isSuccess && !hasError,
                                'd-block': isSuccess || hasError,
                            }"
                        >
                            <div
                                class="alert"
                                v-bind:class="{
                                    'alert-danger': hasError,
                                    'alert-success': isSuccess,
                                }"
                            >
                                {{ alertText }}
                            </div>
                        </div>
                    </div>
                    <div class="row pb-4 pl-4 pr-4">
                        <div class="col-md-12">
                            <form
                                v-on:submit="submit"
                                id="contactForm"
                                class="contact-us-form"
                            >
                                <!-- <h5>Criar conta</h5> -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="name"
                                                v-model="name"
                                                placeholder="Nome Completo"
                                                required="required"
                                                maxlength = "100"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="email"
                                                class="form-control"
                                                name="email"
                                                v-model="email"
                                                placeholder="E-mail"
                                                required="required"
                                                maxlength = "100"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                value=""
                                                class="form-control"
                                                v-model="phone"
                                                @input="updatePhone"
                                                v-mask='"(##) #####-####"'
                                                placeholder="Telefone"
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="password"
                                                name="password"
                                                value=""
                                                class="form-control"
                                                v-model="password"
                                                placeholder="Senha"
                                                required="required"
                                                maxlength = "10"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                value=""
                                                class="form-control"
                                                v-model="confirmPassword"
                                                placeholder="Confirmar Senha"
                                                required="required"
                                                maxlength = "10"
                                            />
                                        </div>
                                    </div>
                                    <div v-if="passwordError" class="col-12">
                                        <p class="text-danger">As senhas não coincidem.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <button
                                            type="submit"
                                            class="btn solid-btn"
                                            id="btnContactUs"
                                            :disabled="passwordError"
                                        >
                                            Cadastrar 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalBase',
    computed: {
        phone: {
            get() {
                return this.$store.getters.getPhone
            },
            set(value) {
                this.$store.dispatch('updatePhone', value)
            }
        },
        passwordError() {
            return this.password && this.confirmPassword && this.password !== this.confirmPassword;
        },
    },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            alertText: "",
            hasError: false,
            isSuccess: false,
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        updatePhone() {
            // Atualizar o telefone via Vuex
            this.$store.dispatch('updatePhone', this.phone)
        },

        submit: async function (e) {

            try {
                
                e.preventDefault();
    
                if (this.passwordError) {
                    this.alertText = "As senhas devem ser iguais.";
                    this.hasError = true;
                    this.isSuccess = false;
                    return;
                }
    
                let formData = new FormData();
    
                formData.append("nome", this.name);
                formData.append("email", this.email);
                formData.append("telefone",this.phone);
                formData.append("password",this.password);
    
                const response = await fetch(`${window.url}cadastro`, { method: "POST", body: formData })
    
                const { data, success, message } = await response.json()

                if(!data || !success) {
                    
                    this.alertText = message || "Erro no cadastro. Tente novamente.";
                    this.isSuccess = false;
                    this.hasError = true;
                    return
                }
    
                this.name = "";
                this.email = "";
                this.phone = "";
                this.password = "";
                this.confirmPassword = "";
                this.alertText = "Usuário cadastrado com sucesso";
                this.hasError = false;
                this.isSuccess = true;

            } catch (error) {

                this.alertText = error.message || "Ocorreu um erro inesperado.";
                this.isSuccess = false;
                this.hasError = true;
                return
                
            }
        },
        mounted() {
            this.alertText = "";
            this.hasError = false;
            this.isSuccess = false;
        },
    },
};
</script>

<style>   
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
        color: #464E5F !important;
        z-index: 9998 !important;
        overflow: scroll;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        background-color: #fff !important;
        width: 488px !important;
        height: 100% !important;
        margin: 0px auto;
        /*padding: 20px 30px;*/
        /*background-color: #fff;*/
        border-radius: 24px !important;
        /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);*/
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .close-button {
        float: right;
        background-color: #fff;
        background-repeat:no-repeat;
        border: none;
        cursor:pointer;
        overflow: hidden;
        outline:none;
        font-weight: 600;
        color: black !important;
    }

</style>