<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
window.url = "https://hml.api.ilub.app/api/v3/";
export default {};
</script>

<style lang = "css">
</style>
