<template>
    <section id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-4">
                    <div class="download-img">
                        <img
                            src="img/mecanico-chave.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="feature-contents section-heading">
                        <h2>
                            Como o iLub Funciona?
                        </h2>
                        <p>
                            Com o iLub, encontrar as informações certas para cada 
                            veículo ficou muito mais fácil! Basta buscar pela placa 
                            ou selecionar a montadora, e o app apresenta detalhes 
                            completos como marca, modelo, ano do veículo, manual técnico, 
                            lubrificante e filtros corretos, além de acesso ao histórico 
                            de atendimentos e muitas outras funcionalidades que tornam 
                            seu trabalho mais eficiente e preciso. 
                        </p>

                        <ul class="list-inline mt-5">
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/car-duotone.svg"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/calendar-duotone.svg"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/galao-oleo-duotone.svg"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/file-invoice-dolar.svg"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
</style>