var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg fixed-top",class:{
            affix: _vm.hasAffix,
            'custom-nav': _vm.coloredLogo,
            'bg-transparent': !_vm.coloredLogo,
            'white-bg': _vm.coloredLogo,
        }},[_c('div',{staticClass:"container"},[_vm._m(0),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse main-menu h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item nav-link page-scroll"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#about'),expression:"'#about'"}],attrs:{"to":"#about"}},[_vm._v("Sobre")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#pricing'),expression:"'#pricing'"}],staticClass:"nav-link page-scroll",attrs:{"to":"#pricing"}},[_vm._v("Preço")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact'),expression:"'#contact'"}],staticClass:"nav-link page-scroll",attrs:{"to":"#contact"}},[_vm._v("Fale conosco")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact'),expression:"'#contact'"}],staticClass:"nav-link page-scroll",attrs:{"to":"#contact"}},[_vm._v("Ir para App")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/logo.svg","width":"100","alt":"logo"}})])
}]

export { render, staticRenderFns }