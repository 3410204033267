import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    phone: ''
  },
  mutations: {
    setPhone(state, phone) {
      state.phone = phone
    }
  },
  actions: {
    updatePhone({ commit }, phone) {
      commit('setPhone', phone)
    }
  },
  getters: {
    getPhone: state => state.phone
  }
})
