<template>
    <footer class="footer-section">
        <div class="shape-img subscribe-wrap">
            <img
                src="img/footer-top-shape.png"
                alt="footer shape"
                class="img-fluid"
            />
        </div>
        <div class="bg-footer-custom footer-top pt-100 pb-5 background-img-2">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-2">
                        <img
                            src="img/logo.svg"
                            width="100"
                            alt="logo"
                            class="img-fluid mt-4"
                        />
                    </div>
                    <div class="col-lg-4">
                        <div class="footer-nav-wrap text-white mt-3">
                            <ul class="social-list list-inline list-unstyled">
                                <li>© Copyright <b>Camp Tecnologia</b></li>
                                <li>Todos os direitos reservados</li>  
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="footer-nav-wrap text-white mt-3">
                            <ul class="social-list list-inline list-unstyled">
                                <li style="text-align: right;"><a href="#" target="_blank">Termos de Uso <span style="vertical-align: middle;font-size:1.8rem;color:#CE1D42;">•</span></a></li>
                                <li style="text-align: right;"><a href="#" target="_blank">Política de privacidade <span style="vertical-align: middle;font-size:1.8rem;color:#CE1D42;">•</span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="footer-nav-wrap text-white">
                            <img
                                src="img/camp.svg"
                                alt="location map"
                                class="img-fluid"
                            />
                        </div>
                    </div>
                </div>
                <hr style="background-color: rgb(255 255 255 / 56%);">
                <div class="row justify-content-between pt-3">
                    <div class="col-lg-6 ml-auto mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <li class="list-inline-item">
                                Blumenau - SC, Rua Dr. Pedro Zimmermmann,<br>
                                2464, Bairro Itoupavazinha
                            </li>
                        </div>
                    </div>
                    <div class="col-lg-6 ml-auto mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <ul class="list-unstyled">
                                <li>
                                    <img
                                        src="img/compra-segura.svg"
                                        width="500"
                                        alt="Compra segura"
                                        class="img-fluid"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
    background: linear-gradient(to right, #000033, #02021D) !important;
}
</style>