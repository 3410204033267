<template>
    <section id="about" class="about-us gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6">
                    <div class="about-content-left section-heading">
                        <h2>
                            Conversão instantânea de produtos
                        </h2>

                        <div class="single-feature mb-4 mt-5">
                            <div
                                class="icon-box-wrap d-flex align-items-center"
                            >
                                <p class="mb-0">
                                    Troque rapidamente entre marcas de óleos e filtros para facilitar a recomendação. Todas as indicações seguem as recomendações do fabricante.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-right">
                        <img
                            src="img/conversao-produtos.png"
                            alt="about us"
                            class="img-fluid pl-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>