<template>
    <section id="promo" class="promo-section pt-100 background-shape-img">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-9">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Indicação de itens de manutenção técnica para cada veículo
                        </h2>
                        <p class="lead">
                            O que o iLub entrega na hora da troca de óleo?
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75" 
                        style="bottom: -60px;position: relative;"
                    >   
                        <div style="position:relative;top:-120px;">
                            <div class="circle-icon">
                                <img
                                    src="img/generic-sedan.png"
                                    alt="sedan"
                                    style="width:290px;height: 165px;position: relative;"
                                />
                            </div>
                            <h5>+27mil veículos</h5>
                            <p>
                                Manual técnico de todos os veículos registrados na tabela FIPE.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75"
                        style="bottom: -60px;position: relative;"

                    >
                        <div style="position:relative;top:-120px;">

                            <div class="circle-icon">
                                <img
                                    src="img/generic-oil.png"
                                    alt="oil"
                                    style="width:200px;height: 170px;position: relative;"
                                />
                            </div>
                            <h5>Óleo do motor ideal</h5>
                            <p>
                                Receba a indicação exata do óleo que atende às especificações do motor do veículo.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75"
                        style="bottom: -60px;position: relative;"
                    >
                        <div style="position:relative;top:-120px;">
                            <div class="circle-icon">
                                <img
                                    src="img/generic-filter.png"
                                    alt="filter"
                                    style="width:200px;height: 170px;position: relative;"
                                />                        
                            </div>
                            <h5>Filtros compatíveis</h5>
                            <p>
                                Identifique e realize e conversão rapidamente dos filtros corretos para cada modelo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>