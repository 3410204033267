import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=2cf40092"
import script from "./Testimonial.vue?vue&type=script&lang=js"
export * from "./Testimonial.vue?vue&type=script&lang=js"
import style0 from "./Testimonial.vue?vue&type=style&index=0&id=2cf40092&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports