<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <placa />
            <companies />
            <promo />
            <about />
            <features />
            <download />
            <pricing  />
            <team :is-gray="true" />
            <contact :is-gray="true" />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-one/Banner";
import Companies from "../../views/commons/Companies";
import Placa from "../../views/index-one/Placa";
import Promo from "../../views/index-one/Promo";
import About from "../../views/index-one/About";
import Features from "../../views/index-one/Features";
import Download from "../../views/index-one/Download";
import Pricing from "../../views/index-one/Pricing";
import Team from "../../views/commons/Team";
import Contact from "../../views/commons/Contact";
import SiteFooter from "../../views/commons/SiteFooter";

export default {
    name: "IndexOne",
    components: {
        NavBar,
        Banner,
        Companies,
        Placa,
        Promo,
        About,
        Features,
        Download,
        Pricing,
        Team,
        Contact,
        SiteFooter,
    },
};
</script>